var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-store-combo", {
                    attrs: {
                      id: "store-id",
                      required: true,
                      filter: function (store) {
                        return store.type === "Kiosk"
                      },
                    },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "id",
                      type: "number",
                      label: _vm.$t("Id"),
                      placeholder: _vm.$t("Id"),
                    },
                    model: {
                      value: _vm.filters.palmBeerBarrelCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "palmBeerBarrelCode", $$v)
                      },
                      expression: "filters.palmBeerBarrelCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice_number",
                      type: "number",
                      label: _vm.$t("Número da nota"),
                    },
                    model: {
                      value: _vm.filters.invoiceNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "invoiceNumber", $$v)
                      },
                      expression: "filters.invoiceNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "brand",
                      type: "vue-select",
                      label: _vm.$t("Marca"),
                      placeholder: _vm.$t("Selecione uma marca"),
                      options: _vm.getComboBrands,
                    },
                    model: {
                      value: _vm.filters.brandId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "brandId", $$v)
                      },
                      expression: "filters.brandId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-search-sku", {
                    attrs: { id: "product-name", required: false },
                    model: {
                      value: _vm.filters.skuId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "skuId", $$v)
                      },
                      expression: "filters.skuId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "active",
                      type: "vue-select",
                      label: _vm.$t("Instalado"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.yesNoTypes(),
                    },
                    model: {
                      value: _vm.filters.installed,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "installed", $$v)
                      },
                      expression: "filters.installed",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "barrels-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.$t("Nenhum barril encontrado"),
              fields: _vm.fields,
              items: _vm.barrels,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      [
                        _c("e-grid-actions", {
                          attrs: {
                            "show-update": false,
                            "show-delete": false,
                            buttons: [
                              {
                                icon: "search",
                                variant: "primary",
                                title: _vm.$t(
                                  "Visualizar histórico de consumo"
                                ),
                                event: "product-read-only",
                              },
                            ],
                          },
                          on: {
                            "product-read-only": function ($event) {
                              return _vm.showConsumptionHistory(row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(installed)",
                fn: function (ref) {
                  var value = ref.value
                  return [
                    _c("e-status-badge", {
                      attrs: { status: value, type: "yesNo" },
                    }),
                  ]
                },
              },
              {
                key: "cell(invoiceNumber)",
                fn: function (row) {
                  return [
                    row.item.purchaseOrder
                      ? _c("e-router-link", {
                          attrs: {
                            to: {
                              name: "order-read-only",
                              params: { id: row.item.purchaseOrderId },
                            },
                            title: _vm.$t("Visualizar Nota Fiscal"),
                            value: row.item.purchaseOrderId,
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "product-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }